
// see: https://usehooks.com/

// https://www.npmjs.com/package/@gatsbyjs/reach-router
// https://reach.tech/router/

import { useMemo } from 'react';

// import { useLocation } from '@gatsbyjs/reach-router';

import { navigate } from 'gatsby';

function useMyRouter() {
	// const location = useLocation();
	return useMemo(() => ({
		push: navigate,
		// location,
		navigate
	}), []);
}

export default useMyRouter;
