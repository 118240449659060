
import React from 'react';
import PropTypes from 'prop-types';

import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import classNames from 'classnames';

import { ItemsType } from '../../../types';

function Features(props) {
	const {
		title, items, onFeature, onAll, darkBackground
	} = props;

	return (
		<section className={classNames('features', { 'dark-background': darkBackground })}>
			<div className={classNames('features--title', { 'light-title': darkBackground })}>
				<h2>{title}</h2>
			</div>

			<div className='features--all'>
				{items.filter((x) => x.active)
					.map((item) => {
						const { image } = item;
						return (
							<div key={item.id} className='features--container'>

								{image !== null && (
									<div className='features--image'>
										<a
											href={item.url}
											target='_blank'
											rel='noopener noreferrer'
											aria-label={item.title}
										>
											<GatsbyImage
												image={getImage(image)}
												alt={item.title}
												objectFit='scale-down'
											/>
										</a>
									</div>
								)}

								{/* {img && (
									<div className='features--image'>
										<a
											href={item.url}
											target='_blank'
											rel='noopener noreferrer'
											aria-label={item.title}
										>
											<img src={img} alt={item.title} />
										</a>
									</div>
								)} */}

								<div className='features--text'>
									<h2>{item.title}</h2>
									<div className='features--text-items'>
										{item.content?.map((subitem) => (
											<div key={subitem.id} className='features--text-item'>{subitem.text}</div>
										))}
									</div>
									<button type='button' className='features--item-button' onClick={() => onFeature(item.url)}>
										Learn More
									</button>
								</div>
							</div>
						);
					})}
			</div>

			<button type='button' className='features--button' onClick={onAll}>
				View All
			</button>

		</section>
	);
}

Features.propTypes = {
	title: PropTypes.string.isRequired,
	items: ItemsType.isRequired,
	onFeature: PropTypes.func.isRequired,
	onAll: PropTypes.func.isRequired,
	darkBackground: PropTypes.bool.isRequired
};

export default Features;

// export const MemoizedProducts = React.memo(Articles);
