
// https://react-icons.github.io/react-icons

import React from 'react';

import { IoIosLeaf, IoMdGlobe } from 'react-icons/io';
import { GiRingedPlanet, GiAbstract094 } from 'react-icons/gi';
import { FaReact, FaJava, FaNodeJs, FaCubes } from 'react-icons/fa';
import { SiMaterialui, SiMongodb, SiOracle, SiGatsby } from 'react-icons/si';

const Services = () => (
	<section className='services'>

		<div className='services-title'>
			<h2>Services</h2>
		</div>

		<div className='service-items'>

			<div className='service-item'>
				<IoMdGlobe />
				<h3>Software Architect</h3>
				<p>
					Extensive experience in the development of architectural frameworks/patterns, application integration,
					processes, standards, and guidelines.
				</p>
			</div>

			<div className='service-item'>
				<GiRingedPlanet />
				<h3>Leadership</h3>
				<p>
					Extensive experience in the development and deployment of highly-complex state-of-the-art software
					solutions.
				</p>
			</div>

			<div className='service-item'>
				<FaCubes />
				<h3>Full Stack Developer</h3>
				<p>
					JavaScript and Java Full Stack application architecture, design and development.
				</p>
			</div>

			<div className='service-item'>
				<FaReact />
				<h3>React</h3>
				<p>
					Full Stack application architecture, design and development
					using React, Redux, Next.js, PWA, HTML5, CSS3, SASS etc.
				</p>
			</div>

			<div className='service-item'>
				<SiGatsby />
				<h3>Gatsby</h3>
				<p>
					Architect and develop Gatsby applications, using React and GraphQL to build SEO
					efficient, high performance PWAs.
				</p>
			</div>

			<div className='service-item'>
				<SiMaterialui />
				<h3>Material UI</h3>
				<p>
					Architect and develop React applications using Material UI framework.
				</p>
			</div>

			<div className='service-item'>
				<FaNodeJs />
				<h3>NodeJS</h3>
				<p>
					Architect and build web and mobile applications using NodeJS and ExpressJS.
				</p>
			</div>

			<div className='service-item'>
				<FaJava />
				<h3>JEE / Java / Spring</h3>
				<p>
					Extensive experience in the analysis, design, development, and implementation of large-scale high
					transaction and high-performance JEE Architecture systems.
				</p>
			</div>

			<div className='service-item'>
				<IoIosLeaf />
				<h3>DevOps</h3>
				<p>
					Continuous Integration and Continuous Delivery, automatically build, test and deploy
					to AWS and Digital Ocean using Docker and Travis thus optimizing release cycles.
				</p>
			</div>

			<div className='service-item'>
				<SiMongodb />
				<h3>NoSQL Databases</h3>
				<p>
					Design and building of high perfoming NoSQL databases like MongoDB.
				</p>
			</div>
			<div className='service-item'>
				<SiOracle />
				<h3>SQL Databases</h3>
				<p>
					Design of databases, tables etc of relational databases, for example Oracle and DB2.
				</p>
			</div>

			<div className='service-item'>
				<GiAbstract094 />
				<h3>Technologist</h3>
				<p>
					Research new technologies for the design and implementation of
					solutions to solve complex business problems.
				</p>
			</div>

		</div>
	</section>
);

export default Services;
