
import React from 'react';

import { graphql, useStaticQuery } from 'gatsby';

import Features from './features/Features';

import useMyRouter from '../../hooks/useMyRouter';

function Articles() {
	const { contentArticlesJson: items } = useStaticQuery(graphql`
		query {
			contentArticlesJson {
				all {
					id
					active
					title
					url
					content {
						id
						text
					}
					image {
						childImageSharp {
							gatsbyImageData(width: 500)
						}
					}
				}
			}
		}
`);

	const myRouter = useMyRouter();

	const onAll = () => {
		myRouter.push('/blog');
	};

	const onFeature = (url) => {
		myRouter.push(url);
	};

	return (
		<Features
			title='Articles'
			items={items.all}
			onFeature={onFeature}
			onAll={onAll}
			darkBackground={false}
		/>
	);
}

export default Articles;

// export const MemoizedArticles = React.memo(Articles);
