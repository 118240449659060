
import React from 'react';

import { graphql, useStaticQuery } from 'gatsby';

function Technologies() {
	const { contentLogosJson: logos } = useStaticQuery(graphql`
	query {
		contentLogosJson {
			all {
				id
				image
				url
				header
			}
		}
	}
`);

	return (
		<section className='technologies'>

			<div className='technologies--title not-empty'>
				<h2>Technologies</h2>
			</div>

			<div className='technologies--all'>
				{logos.all.map((logo) => {
					const { id, image, header } = logo;
					if (!image) return '';
					const path = `/images/logos/${image}`;
					const key = `logos-${id}`;
					return (
						<div key={key} className='technologies--image tech-tooltip'>
							<img src={path} alt={header} />
							<span className='tech-tooltiptext'>{header}</span>
						</div>
					);
				})}
			</div>

		</section>
	);
}

export default Technologies;
