
import React, { useState, useRef } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

// import useOnScreen from '../hooks/useOnScreen';
// import useWindowSize from '../hooks/useWindowSize';

import MainLayout from '../components/MainLayout';

import SubpageHeader from '../components/SubpageHeader';
import Services from '../components/home/Services';

import Articles from '../components/home/Articles';
// import EmptyArticles from '../components/home/EmptyArticles';
// import Products, { MemoizedProducts } from '../components/home/Products';
import Products from '../components/home/Products';

import Technologies from '../components/home/Technologies';

// import EmptyProducts from '../components/home/EmptyProducts';

// import EmptyTechnologies from '../components/home/EmptyTechnologies';

import createMetaData from '../utils/createMetaData';

// const LazyTechnologies = React.lazy(() => import('../components/home/Technologies'));

// import OnScreen from '../components/home/OnScreen';
// import Balls from '../components/home/Balls';
// import Pictures from '../components/home/Pictures';

// import Contact from '../components/home/Contact';

const Home = () => {
	// const [onScreenProducts, setOnScreenProducts] = useState(false);
	// const [onScreenArticles, setOnScreenArticles] = useState(false);
	// const [onScreenTechnologies, setOnScreenTechnologies] = useState(false);

	const { pageHomeJson: json } = useStaticQuery(graphql`
		query {
			pageHomeJson {
				title
				subtitle
				subsubtitle
				lead
				permalink
				description
			}
		}
	`);

	// const size = useWindowSize();

	// const refProducts = useRef();
	// const refArticles = useRef();
	// const refTechnologies = useRef();

	// const jv1 = useOnScreen(refProducts, '-100px');
	// const jv2 = useOnScreen(refArticles, '-100px');
	// const jv3 = useOnScreen(refTechnologies, '-100px');

	// if (!onScreenProducts && jv1) setOnScreenProducts(true);
	// if (!onScreenArticles && jv2) setOnScreenArticles(true);
	// if (!onScreenTechnologies && jv3) setOnScreenTechnologies(true);

	const { site, page, seo } = createMetaData(json);
	return (
		<MainLayout site={site} page={page} seo={seo}>
			<SubpageHeader page={page} />

			{/* {size && size.width
			&& <MemoizedProducts size={size} />			} */}

			<Services />

			<Products />

			<Articles />

			<Technologies />

			{/* <React.Suspense fallback={<div />}>
				<LazyTechnologies />
			</React.Suspense> */}

			{/* <div ref={refProducts}>
				{onScreenProducts && size && size.width ? <MemoizedProducts size={size} /> : <EmptyProducts />}
			</div> */}

			{/* <div ref={refArticles}>
				{onScreenArticles ? <Articles /> : <EmptyArticles />}
			</div> */}

			{/* <div ref={refTechnologies}>
				{!onScreenTechnologies && <EmptyTechnologies />}
				{onScreenTechnologies && (
					<React.Suspense fallback={<div />}>
						<LazyTechnologies />
					</React.Suspense>
				)}
			</div> */}

		</MainLayout>
	);
};

export default Home;
