
import React from 'react';

import { graphql, useStaticQuery } from 'gatsby';

import Features from './features/Features';

import useMyRouter from '../../hooks/useMyRouter';

function Products() {
	const { contentProductsJson: items } = useStaticQuery(graphql`
		query {
			contentProductsJson {
				all {
					id
					active
					title
					url
					content {
						id
						text
					}
					image {
						childImageSharp {
							gatsbyImageData(width: 500)
						}
					}
				}
			}
		}
		`);

	const myRouter = useMyRouter();

	const onAll = () => {
		myRouter.push('/portfolio');
	};

	const onFeature = (url) => {
		myRouter.push(url);
	};

	return (
		<Features
			title='Products'
			items={items.all}
			onFeature={onFeature}
			onAll={onAll}
			darkBackground
		/>
	);
}

export default Products;

// export const MemoizedProducts = React.memo(Products);
